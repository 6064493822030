@use 'processing-steps';

processing-steps.$item-height: 64px;

.ingredient {
	border-bottom: 1px solid var(--color-border);
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: processing-steps.$item-height;

	&__amount {
		font-family: var(--font-primary);
		font-size: 12px;
		max-width: 140px;
	}

	&__information {
		max-width: 250px;
	}

	&__priority {
		max-width: 250px;
	}
}

recipe-step-forms-container {
	.list-view .cooking-variable.row,
	.list-view .ingredient.row {
		min-height: processing-steps.$item-height;
		padding: 0;
	}
}

.row {
	.ingredient {
		&__title {
			color: var(--color-primary-600);
			font-weight: bold;
			font-family: var(--font-ternary);
			font-size: 14px !important; /* stylelint-disable-line */
			flex-grow: 1;
		}
	}

	.conversion-info,
	.mapping-info {
		margin-right: 32px;
	}
}

.no-items {
	padding: 24px;
}

// Custom print rules for recipe printing
@media print {
	.ingredient {
		padding: 10px 0;
	}

	.row {
		min-height: processing-steps.$item-height !important; /* stylelint-disable-line */
	}
}
