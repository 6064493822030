/* stylelint-disable */

.nutriu {
	a.button,
	button.button,
	button.mat-button,
	button.mdc-button {
		min-width: 125px;
		border: 1px solid var(--color-primary-500);
		border-radius: 2px;
		box-shadow: none;
		background-color: var(--color-primary-500);
		color: var(--color-white);
		transition: all 0.2s;
		letter-spacing: normal;
		height: 38px;
		font-size: 14px;

		&:hover,
		&:focus {
			background-color: var(--color-primary-600);
			color: var(--color-white);
			box-shadow: none;
		}

		&:active {
			background: var(--color-primary-400);
			color: var(--color-white);
		}

		// todo
		&[disabled] {
			background-color: var(--color-primary-500);
			color: var(--color-white);
			opacity: 0.4;
		}

		&--spacious {
			padding-left: 16px;
			padding-right: 16px;
		}

		&--action {
			min-width: 109px;
		}

		// todo
		&--unelevated {
			color: var(--color-grey-800);
			background-color: var(--color-white);
			border: 1px solid var(--color-white);

			&:hover,
			&:focus {
				color: var(--color-grey-800);
				background-color: var(--color-white);
			}

			&:hover .mat-mdc-button-persistent-ripple::before {
				opacity: 0;
			}

			&:disabled {
				color: var(--color-grey-300);
				background: var(--color-grey-100);
			}
		}

		&--inverse {
			background-color: var(--color-white);
			border: 1px solid var(--color-primary-500);
			color: var(--color-primary-500);
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: var(--color-white);
				color: var(--color-primary-700);
				stroke: 1.5;
				border: 1px solid;
				border: 1px solid var(--color-primary-700);
			}

			&:active {
				background-color: var(--color-white);
				border: 1px solid var(--color-primary-700);
				color: var(--color-primary-700);
			}

			&[disabled] {
				background-color: var(--color-white);
				color: var(--color-grey-500);
				border-color: var(--color-disabled);
				opacity: 1;
			}
		}

		&--warn-inverse {
			background-color: var(--color-white);
			border: 1px solid var(--color-error-500);
			color: var(--color-error-500);
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: var(--color-white);
				stroke: 1.5;
				color: var(--color-error-600);
				border: 1px solid var(--color-error-600);
			}

			&:active {
				background-color: var(--color-white);
				border: 1px solid var(--color-error-400);
				color: var(--color-error-400);
			}

			&[disabled] {
				background-color: var(--color-white);
				color: var(--color-grey-500);
				border-color: var(--color-disabled);
				opacity: 1;
			}
		}

		&--warn {
			background-color: var(--color-error-500);
			border: 1px solid var(--color-error-500);
			border-radius: 2px;
			color: var(--color-white);
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: var(--color-error-600);
				color: var(--color-white);
			}

			&:active {
				background-color: var(--color-error-400);
				color: var(--color-white);
			}

			&[disabled] {
				background-color: var(--color-error-100);
				border-color: var(--color-error-100);
				color: var(--color-white);
				opacity: 1;
			}
		}

		&--basic {
			background-color: var(--color-primary-500);
			border: 1px solid var(--color-primary-500);
			border-radius: 2px;
			color: var(--color-white);
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: var(--color-primary-600);
				border-color: var(--color-primary-600);
				color: var(--color-white);
			}

			&:active {
				background-color: var(--color-primary-400);
				border-color: var(--color-primary-400);
				color: var(--color-white);
			}

			&[disabled] {
				background-color: var(--color-grey-300);
				border-color: var(--color-border);
				color: var(--color-grey-500);
				opacity: 1;
			}
		}

		&--small {
			min-width: auto;
		}

		&--large {
			min-width: 400px;
		}

		.mat-button-wrapper {
			font-weight: normal;
		}

		&.button--dynamic {
			min-width: auto;
		}
	}

	.mdc-button.text-button-dropdown,
	.mdc-button.solid-button-dropdown {
		.mdc-button__label {
			display: inline-flex;
			align-items: center;
		}
	}

	.text-button,
	.mat-button.text-button,
	.mat-mdc-button.text-button {
		display: inline-flex;
		align-items: center;
		color: var(--color-primary-500);
		font-family: var(--font-secondary);
		font-weight: normal;
		cursor: pointer;
		margin-right: 0;
		border: none;
		background-color: transparent;
		box-shadow: none;
		line-height: 1;

		.mdc-button__label {
			display: inline-flex;
			align-items: center;
		}

		.mat-button-focus-overlay {
			background-color: transparent;
		}

		&:hover,
		&:focus {
			background-color: transparent;
			color: var(--color-primary-500);
			text-decoration: underline;
		}

		&:active {
			background-color: transparent;
		}

		&[disabled] {
			background-color: transparent;
			color: var(--color-grey-500);
			opacity: 0.3;
			text-decoration: none;
		}

		.mat-button-ripple.mat-ripple {
			display: none;
		}

		.mdc-button__ripple,
		.mat-mdc-button-ripple {
			display: none;
		}

		&.icon-button {
			font-weight: normal;
			text-decoration: none;
			background-color: transparent;
			border: none;

			mat-icon {
				width: 24px;
				height: 24px;
				font-size: 24px;
			}

			&.mat-raised-button {
				padding-left: 16px;
			}

			&:hover,
			&:focus {
				background-color: var(--color-primary-100);
			}

			&:active {
				background-color: var(--color-primary-100);
				border: none;
			}
		}

		&--white {
			background-color: var(--color-white);
			color: var(--color-primary-500);
			font-family: var(--font-secondary);
			font-weight: normal;
			border: none;

			&:hover,
			&:focus {
				background-color: var(--color-grey-200);
				text-decoration: underline;
				color: var(--color-primary-500);
			}

			&:active {
				background-color: var(--color-white);
				text-decoration: underline;
			}

			&.icon-button {
				&:hover,
				&:focus {
					background-color: var(--color-grey-200);
					text-decoration: none;
					color: var(--color-primary-500);

					img {
						filter: brightness(0.9);
					}
				}

				&:active {
					background-color: var(--color-primary-300);
					text-decoration: none;
					border: 1px solid transparent;
					border-radius: 2px;
				}
			}
		}

		&.delete-button {
			color: var(--color-error-500);
		}
	}

	.icon-button,
	.mat-button.icon-button {
		border: 1px solid transparent;
		border-radius: 2px;
		background-color: var(--color-white);

		&.mat-raised-button {
			padding-left: 6px;
		}

		img {
			margin-right: var(--margin-button-icon);
		}

		&:hover,
		&:focus {
			background-color: var(--color-white);
			cursor: pointer;

			img {
				filter: brightness(0.85);
			}
		}

		&:active {
			border: solid var(--color-success-500) 1px;
			border-radius: 2px;
			background-color: var(--color-primary-300);
		}

		&[disabled] {
			color: var(--color-grey-500);
			opacity: 1;
		}
	}

	.mat-button,
	.mat-raised-button.button {
		.mat-button-focus-overlay {
			opacity: 0;
		}

		&:hover {
			.mat-button-focus-overlay {
				opacity: 0;
			}
		}
	}

	.button-icon-padded {
		margin-right: 18px;
	}

	.button.icon-top-button {
		align-items: center;
		background-color: var(--color-white);
		border: 1px solid var(--color-primary-500);
		color: var(--color-primary-500);
		display: flex;
		flex-direction: column;
		font-size: 12px;
		height: 68px;
		justify-content: center;
		line-height: 18px;
		min-width: 80px;
		padding: 4px;
		width: auto;
		outline: none;

		mat-icon {
			margin-bottom: 6px;
		}

		&:hover {
			cursor: pointer;
		}

		&:hover,
		&:focus {
			background-color: var(--color-primary-100);
			color: var(--color-primary-600);
			border: 1px solid var(--color-primary-600);
		}

		&:active {
			background-color: var(--color-primary-100);
			border: 1px solid var(--color-primary-700);
			color: var(--color-primary-700);
		}

		&[disabled] {
			background-color: var(--color-white);
			color: var(--color-grey-500);
			border-color: var(--color-disabled);
			cursor: default;
			opacity: 1;
		}

		&--warn {
			background-color: var(--color-white);
			border: 1px solid var(--color-error-500);
			color: var(--color-error-500);

			&:hover,
			&:focus {
				background-color: var(--color-error-100);
				color: var(--color-error-600);
				border: 1px solid var(--color-error-600);
			}

			&:active {
				background-color: var(--color-error-100);
				border: 1px solid var(--color-error-400);
				color: var(--color-error-400);
			}

			&[disabled] {
				background-color: var(--color-white);
				color: var(--color-grey-500);
				border-color: var(--color-disabled);
			}
		}
	}
}
