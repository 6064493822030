@use 'sass:color';

// Set of variables that need to be themeable
@mixin theme-nutriu {
	--color-landing-page: #0056b3;
	--color-primary-100: #ebf4ff;
	--color-primary-200: #cce2fa;
	--color-primary-300: #acd0f5;
	--color-primary-400: #569eeb;
	--color-primary-500: #006ce0;
	--color-primary-600: #0056b3;
	--color-primary-700: #053d85;
	--color-primary-800: #0a2457;
	--color-primary-900: #122348;
	--color-text: #17191d;
	--gradient-sidenav: linear-gradient(225deg, #152d8c 0%, #0c6399 50%, #02a0a4 100%);
}

@mixin theme-xelsis {
	--color-landing-page: #ad876e;
	--color-primary-100: #f3efec;
	--color-primary-200: #decfc5;
	--color-primary-300: #c8ae9e;
	--color-primary-400: #ad876e;
	--color-primary-500: #8c6e59;
	--color-primary-600: #6b5444;
	--color-primary-700: #4a3a2f;
	--color-primary-800: #392d25;
	--color-primary-900: #28201a;
	--color-text: #1d1b17;
	--gradient-sidenav: linear-gradient(225deg, #4e3e37 0%, #ad876e 50%, #ccb5a3 100%);
}

// definition of the default color CSS variables, override them via theme mixins
:root {
	// default color CSS variables
	--color-error-100: #f9e8ea;
	--color-error-400: #ec5f67;
	--color-error-500: #e83a44;
	--color-error-600: #db1a25;
	--color-error-700: #a4131c;
	--color-primary-100: #ebf4ff;
	--color-primary-200: #cce2fa;
	--color-primary-300: #acd0f5;
	--color-primary-400: #569eeb;
	--color-primary-500: #006ce0;
	--color-primary-600: #0056b3;
	--color-primary-700: #053d85;
	--color-primary-800: #0a2457;
	--color-primary-900: #122348;
	--color-success-100: #d7f4dc;
	--color-success-500: #37c852;
	--color-success-700: #217831;
	--color-warn-100: #fcecd6;
	--color-warn-400: #f4b55d;
	--color-warn-500: #f2a83e;
	--color-warn-600: #f09d28;
	--color-warn-700: #ee9211;

	// grayscale color palette
	--color-black: #000000;
	--color-grey-100: #f4f4f4;
	--color-grey-200: #efefef;
	--color-grey-300: #e5e5e5;
	--color-grey-400: #d8d8d8;
	--color-grey-500: #b6b6b6;
	--color-grey-600: #949494;
	--color-grey-700: #6e6e6e;
	--color-grey-800: #3c3c3c;
	--color-grey-900: #1e1e1e;
	--color-white: #ffffff;

	// default feature color CSS variables
	--color-calendar-legend: #00bcb8;
	--color-landing-page: #1a1a87;
	--gradient-sidenav: linear-gradient(225deg, #152d8c 0%, #0c6399 50%, #02a0a4 100%);

	// System overrides for MDC components
	--mdc-chip-outline-color: transparent;

	.theme-nutriu {
		@include theme-nutriu;
	}

	.theme-xelsis {
		@include theme-xelsis;
	}
}

* {
	// override the default color CSS variables with the theme variables
	--color-border: var(--color-grey-300);
	--color-disabled: var(--color-grey-400);
	--color-label: var(--color-primary-900);
	--color-placeholder: var(--color-grey-500);
	--color-text-light: var(--color-grey-800);
	--color-text: var(--color-grey-900);
	--mdc-radio-selected-focus-icon-color: var(--color-label);
	--mdc-radio-selected-hover-icon-color: var(--color-label);
	--mdc-radio-selected-icon-color: var(--color-label);
}
