/* You can add global styles to this file, and also import other style files */
@use 'assets/styles/utils/dam-shared-variables';

.dam-header {
	display: flex;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 2;
	background: var(--dam-color-white);
	padding: 36px 40px 32px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%);

	h1 {
		font-family: var(--dam-font-ternary), sans-serif;
		font-size: 36px;
		line-height: 32px;
		margin: 0;
		color: var(--dam-color-primary-500);
	}

	&--short {
		padding: 16px 30px;
		align-items: center;

		h1 {
			font-family: var(--dam-font-primary), sans-serif;
			font-size: 20px;
			font-weight: normal;
			color: var(--dam-color-black);
		}
	}
}

.dam-dialog {
	background-color: var(--dam-color-white);

	&-title {
		margin-top: 0;
	}

	&-description {
		margin-bottom: 20px;
	}

	&-actions {
		display: flex;
		justify-content: space-between;
	}
}

.mat-mdc-select-panel.dam-tags-dropdown-panel {
	background-color: var(--dam-color-white);
}
