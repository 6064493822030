*,
*:before,
*:after {
	box-sizing: border-box;
}

/* stylelint-disable selector-no-type */
html,
body {
	height: 100%;
	min-height: 100%;
}

body {
	font-family: var(--font-primary);
	font-size: 14px;
	line-height: 22px;
	margin: 0;
	color: var(--color-text-light);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* stylelint-enable selector-no-type */

.main-container {
	background-color: var(--color-grey-200);
}

input:disabled {
	background: transparent;
}
