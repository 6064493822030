$upload-normal: 0;
$section-box-form: 2;
$upload-touchable: 5;
$upload-cropper: 10;
$upload-button: 15;
$upload-progress: 20;
$cropper-placeholder-message: 25;
$cropper-preview: 30;
$fixed-navigation: 100;
$select-field: 1000;
