.link {
	color: var(--color-primary-500);
	text-decoration: none;

	&--bold {
		font-weight: bold;
	}

	&:hover {
		text-decoration: underline;
	}
}

.link-dark {
	color: var(--color-label);
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.list-row {
	background-color: var(--color-white);
	color: var(--color-text);

	&:hover {
		background-color: var(--color-primary-100);
	}
}

.bar-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.bar-view-container {
	display: flex;
	height: 100vh;
}

.bar-list {
	flex: 1;
}

@media print {
	.bar-view-container {
		height: 100%;
		overflow-y: visible;
	}
}

.padding {
	padding: 16px;
}
