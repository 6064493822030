mat-chip-option.mat-mdc-chip-option.mat-mdc-chip {
	display: inline-block;
	background-color: var(--color-primary-100);
	padding: 4px 12px 4px 6px;
	border-radius: 4px;
	cursor: default;
	font-size: 14px;
	line-height: 22px;

	&:focus {
		outline: none;
	}

	.mat-mdc-chip-focus-overlay {
		opacity: 0;
	}

	.mat-mdc-chip-action {
		padding-left: 0;
		padding-right: 0;
		cursor: default;
	}

	.chip-option-content {
		display: flex;
		align-items: center;
	}

	.icon {
		display: inline-block;
		height: 22px;
		width: 22px;
		padding: 0;
		opacity: 1;
		cursor: pointer;
	}

	.label {
		font-family: var(--font-secondary);
		font-size: 12px;
		font-weight: bold;
		display: inline-block;
		margin: 0;
		margin-left: 8px;
		max-width: 300px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		min-height: 22px;
		letter-spacing: initial;
	}
}

.dropdown-container .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
	margin: 0 0 -4px 0;
}
