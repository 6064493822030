/**
 * Copyright (C) 2021 - 2021 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// Custom print rules for recipe printing
@media print {
	.hide-for-printing {
		display: none;
	}

	.page-break-before {
		page-break-before: always;
	}
}
