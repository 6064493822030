/* stylelint-disable */

/* Override material dialog component */

.cdk-overlay-container {
	.cdk-overlay-dark-backdrop {
		background: var(--color-white);
		opacity: 0.5;
	}

	.cdk-global-overlay-wrapper {
		.publishing-problems-modal {
			min-width: 750px;
		}
	}
}

.nutriu {
	.mat-mdc-dialog-surface {
		padding: 24px;
		background: var(--color-white);
		box-shadow: 0px 10px 36px 8px rgba(0, 0, 0, 0.25) !important;
		border-radius: 4px;
		overflow: visible !important;
		color: var(--color-text-light);

		.mat-mdc-dialog-title.mdc-dialog__title {
			font-family: var(--font-secondary);
			font-weight: normal;
			word-wrap: break-word;
			max-height: 300px;
			overflow: auto;
			margin: 0 0 12px;
			line-height: 1.8;
			padding: 0;
			letter-spacing: 0.25px;
			color: var(--color-text-light);
			font-size: 22px;

			&::before {
				display: none;
			}
		}

		.mat-dialog-subtitle {
			color: var(--color-text-light);
			line-height: 1.5;
			padding: 0 0 12px;
			margin: 0;
		}

		.mat-mdc-dialog-content.mdc-dialog__content {
			--mdc-dialog-supporting-text-tracking: normal;
			--mdc-dialog-supporting-text-color: $base-grey-color;

			padding: 0;
			font-size: 16px;
			line-height: 1.8;

			.dialog-content-item {
				padding: 12px 0;

				&--form-field {
					padding-bottom: 20px;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}

			.error-subtitle {
				color: var(--color-error-500);
				font-size: 14px;
				margin-top: 32px;
			}
		}

		.mat-mdc-dialog-actions {
			padding: 30px 0 0;
			border-top-width: 0;

			button:not(:last-child) {
				margin-right: 8px;
				margin-left: 0;
			}

			button:last-child {
				margin: 0;
			}
		}
	}
}
