/* stylelint-disable */

mat-tooltip-component .mdc-tooltip {
	.mdc-tooltip__surface {
		background-color: var(--color-label) !important;
		font-family: var(--font-secondary) !important;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: -0.1px;
		height: auto !important;
		white-space: pre-line;
		line-height: 14px;
		max-width: 250px;
		padding: {
			top: 6px !important;
			bottom: 6px !important;
		}
		text-align: center;
	}
}
