$item-height: 150px;

.processing-step {
	padding: 0 24px;

	&:not(:last-child) {
		border-bottom: 1px solid var(--color-border);
	}

	&__title {
		font-family: var(--font-ternary);
		font-weight: bold;
		font-size: 16px;
		color: var(--color-primary-600);
		margin: 0;
		margin-top: 20px;
	}

	&__content {
		display: flex;
		height: $item-height;
		margin: {
			top: 27px;
			bottom: 27px;
		}
	}

	&-item {
		height: $item-height;
		line-height: 1.5;
		overflow: hidden;
		display: inline-block;

		&:not(:first-child) {
			margin: 0 0 0 48px;
		}

		&__image {
			width: 200px;
			min-width: 200px;

			background: {
				image: url('/assets/img/ic-camera-placeholder.svg');
				color: var(--color-grey-200);
				position: center center;
				repeat: no-repeat;
			}
		}

		&__step-title {
			width: 240px;

			.description {
				overflow: scroll;
				height: 120px;
			}
		}

		&__description {
			max-width: 720px;
			flex-basis: 35%;
			flex-shrink: 0;

			.description {
				overflow: auto;
				height: 120px;
			}
		}
	}

	recipe-step-list-item
		recipe-step-cooking-variable-collapsible-item:first-of-type
		recipe-step-cooking-variable-preview {
		border-top: none;
	}
}
