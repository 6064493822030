* {
	--dam-font-primary: var(--font-primary, 'NeueFrutigerWorld-Book', sans-serif);
	--dam-font-secondary: var(--font-secondary, 'NeueFrutigerWorld', sans-serif);
	--dam-font-ternary: var(--font-ternary, 'NeueFrutigerWorld-X', sans-serif);
	--dam-color-primary-500: var(--color-primary-500, #006ce0);
	--dam-color-primary-100: var(--color-primary-100, #006ce0);
	--dam-color-black: var(--color-black, #000000);
	--dam-color-white: var(--color-white, #ffffff);
	--dam-color-error-500: var(--color-error-500, #e83a44);
	--dam-color-error-100: var(--color-error-100, #f9e8ea);
	--dam-color-grey-500: var(--color-grey-500, #949494);
	--dam-color-grey-200: var(--color-grey-200, #efefef);
	--dam-color-placeholder: var(--color-placeholder, #b6b6b6);
}

// material overrides
digital-asset-management {
	--mat-table-background-color: #ffffff;
	--mat-table-row-item-outline-color: var(--dam-color-grey-200);
	--mat-table-row-item-label-text-font: var(--dam-font-primary);
	--mat-table-header-headline-font: var(--dam-font-secondary);
}
