.nutriu {
	.mat-accordion {
		.mat-expansion-panel {
			&:first-of-type {
				border-radius: 0;
			}

			&:last-of-type {
				border-radius: 0;
			}
		}
	}
}
