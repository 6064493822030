@mixin form-column($n) {
	&-#{$n} {
		flex: #{$n};
	}
}

.form-grid {
	display: flex;
	flex-wrap: wrap;
	column-gap: var(--gap-form-column);

	&--separated {
		margin-bottom: var(--spacing);
	}

	.form-row {
		margin-bottom: 32px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: var(--gap-form-column);
		flex-grow: 1;
	}

	.form-column {
		flex-basis: calc((100% - #{var(--gap-form-column)}) / 2);

		@for $i from 1 through 12 {
			@include form-column($i);
		}

		&--full {
			flex: 0 0 100%;
		}

		&.hidden {
			display: none;
		}
	}

	.form-field-box {
		display: block;
		margin: 16px 0;

		&--halved {
			margin: 8px 0;
		}

		&--doubled {
			margin: {
				top: 32px;
				bottom: 32px;
			}
		}

		&--tag-multiselect {
			margin-top: 8px;
		}
	}
}

.form-name {
	font-family: var(--font-secondary);
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	margin: 0;
	padding: 20px 0 16px;

	&--dark {
		font-weight: 500;
	}
}

.form-group {
	display: flex;
	column-gap: var(--gap-form-column);
	flex-grow: 1;
	flex-wrap: wrap;
	align-items: center;

	.form-group-item {
		flex: 1;
	}
}

.form-actions {
	text-align: right;

	button.button,
	button.mat-button,
	button.text-button {
		&:not(:last-of-type) {
			margin-right: var(--spacing);
		}
	}
}
