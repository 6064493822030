.mat-mdc-paginator {
	$button-size: 40px;
	$button-icon-size: 28px;
	$label-color: rgba(0, 0, 0, 54%);
	$disabled-button-icon-color: rgba(0, 0, 0, 26%);

	--mat-paginator-container-text-tracking: normal;
	--mat-paginator-container-text-color: $label-color;

	.mat-mdc-icon-button {
		padding: 6px;
		width: $button-size;
		height: $button-size;
		font-size: calc($button-size / 2);

		&:disabled {
			.mat-mdc-paginator-icon {
				fill: $disabled-button-icon-color;
			}
		}

		svg {
			--mdc-icon-button-icon-size: $button-icon-size;
		}

		.mat-mdc-button-touch-target {
			width: $button-size;
			height: $button-size;
		}
	}

	.mat-mdc-button-persistent-ripple {
		display: none;
	}
}
