/* stylelint-disable */

.nutriu {
	.navigation-sidebar-container {
		.mat-expansion-panel .mat-expansion-panel-header:not(.mat-expanded) {
			&:hover {
				background: rgba(0, 0, 0, 0.2);
			}
		}
	}

	.mat-expansion-panel {
		background: transparent;
		box-shadow: none;

		&-header {
			padding: 0;
			font-family: var(--font-secondary);
			height: auto;

			&.mat-expanded {
				height: auto;
				&:hover {
					background: rgba(0, 0, 0, 0.2);
				}
			}

			&:hover {
				background: rgba(0, 0, 0, 0.2);
			}
		}

		&-body {
			margin: 0;
			padding: 0;
		}

		&:not([class*='mat-elevation-z']) {
			box-shadow: none;
		}
	}

	.mat-action-row {
		justify-content: flex-start;
		padding: 0;
		border: none;
	}

	.mat-expansion-indicator {
		padding: 8px 16px;

		&:after {
			color: var(--color-white);
		}
	}

	.primary {
		.mat-expansion-indicator:after {
			color: var(--color-primary-500);
		}
	}

	recipe-step-forms-container {
		.mat-expansion-panel.mat-expanded {
			background-color: transparent !important;

			.list-view .content,
			.list-view .header,
			.list-view .rows,
			.list-view .list-row {
				background-color: transparent !important;
			}
		}

		.mat-expansion-panel-header:hover,
		.mat-expansion-panel.mat-expanded:hover {
			background-color: rgba(9, 10, 4, 0.03) !important;

			.list-view .content,
			.list-view .header,
			.list-view .rows,
			.list-view .list-row {
				background-color: (9, 10, 4, 0.03) !important;
			}
		}
	}

	recipe-step-forms-container .is-error {
		.mat-expansion-panel.mat-expanded,
		.mat-expansion-panel.mat-expanded:hover {
			background-color: var(--color-error-100) !important;

			.list-view .content,
			.list-view .header,
			.list-view .rows,
			.list-view .list-row {
				background-color: var(--color-error-100) !important;
			}
		}
	}

	recipe-step-list-item {
		.mat-expansion-panel-body {
			padding-left: 0;
			padding-right: 0;
		}
	}

	recipe-step-cooking-variable-collapsible-item {
		.mat-expansion-panel-header {
			overflow: hidden;

			.mat-expansion-panel-header-title {
				margin-right: 0;
			}

			.mat-expansion-indicator {
				display: none;
			}
		}

		.mat-expansion-panel.mat-expanded {
			recipe-step-cooking-variable-item {
				border-top: 1px solid var(--color-border);
			}
		}
	}

	.expandable-sub-section .mat-expansion-panel {
		border-radius: 0;

		&:hover {
			background-color: none;
		}

		&-header {
			padding: 0 24px;

			&-title {
				color: var(--color-primary-600);
				font-family: var(--font-secondary);
				font-size: 12px;
				font-weight: 500;
				margin: 0;
				text-transform: uppercase;
			}

			.mat-expansion-indicator::after {
				color: var(--color-primary-500);
			}
		}
	}

	step-descriptions {
		.mat-expansion-indicator::after {
			color: var(--color-primary-500);
		}

		.mat-expansion-panel-body {
			background-color: var(--color-grey-100);
			padding: 0 24px;
		}
	}

	search-filters .filters {
		mat-expansion-panel-header.mat-expansion-panel-header {
			transition: background-color 0.2s;

			&:hover {
				background: rgba(0, 0, 0, 0.05);
			}
		}
	}
}
