.nutriu {
	.mat-calendar {
		background-color: var(--color-white);
		box-shadow: var(--box-shadow);
		font-weight: bold;

		.mat-calendar-header {
			padding-top: 0;

			.mat-calendar-period-button {
				background: transparent;
				border: none;
				color: var(--color-text-light);
			}
		}
		.mat-calendar-body-cell-content {
			border-radius: 4px;

			&.mat-calendar-body-selected {
				color: var(--color-primary-600);
				background-color: var(--color-primary-300);
				font-family: var(--font-ternary);
			}
		}
		.mat-calendar-table-header {
			font-weight: bold;
		}
	}
}
