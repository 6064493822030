@use 'utils/z-indexes';

/* Overrides for new dropdown component */
.select-panel.mat-mdc-select-panel {
	.mat-pseudo-checkbox {
		border-color: var(--color-label);
		background-color: transparent;
		margin-right: 16px;
	}

	.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
		border-color: var(--color-label);
		background-color: var(--color-label);

		&::after {
			color: var(--color-white);
		}
	}

	.mat-option-disabled {
		.mat-pseudo-checkbox {
			border-color: var(--color-grey-700);
		}

		.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
			border-color: var(--color-grey-700);
			background-color: var(--color-grey-700);
		}
	}

	.options.hidden {
		display: none !important;
	}

	.mat-mdc-option {
		--mat-option-focus-state-layer-color: #{var(--color-primary-100)};
		--mat-option-selected-state-layer-color: #{var(--color-primary-100)};
		min-height: var(--height-dropdown-option);

		&:hover:not(.mdc-list-item-disabled) {
			background-color: var(--color-primary-100);
		}

		&:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
			display: none;
		}

		&.hidden {
			display: none !important;
		}

		.mdc-list-item__primary-text {
			color: var(--color-text-light);
			letter-spacing: normal;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			font: {
				family: var(--font-primary);
				size: 16px;
			}
		}

		&.mdc-list-item--selected {
			font-weight: bold;
		}

		&.mdc-list-item--disabled {
			.mdc-list-item__primary-text {
				color: var(--color-grey-700);
			}

			&.mdc-list-item--selected {
				.mdc-list-item__primary-text {
					color: var(--color-grey-700);
				}
			}
		}
		&.not-found {
			.mdc-list-item__primary-text {
				color: var(--color-primary-600);
			}
		}
	}

	&.mdc-menu-surface {
		padding: 0;
	}
}

.mat-mdc-select .mat-mdc-select-trigger {
	font-size: 14px;
	letter-spacing: normal;
	display: flex;
	justify-content: center;

	.mat-mdc-select-arrow-wrapper {
		width: 14px;
		transform: translateY(1px);
	}

	.mat-mdc-chip {
		margin: 0 4px 4px 0;
	}

	.mat-mdc-chip-action {
		&::before {
			border: none;
		}
	}
}

.nutriu {
	.mat-mdc-optgroup-label {
		font-family: var(--font-secondary);
		font-size: 12px;
		font-weight: 500;
		line-height: 1.5;
		text-align: left;
		color: var(--color-primary-600);
		text-transform: uppercase;
		padding-left: 16px;
		min-height: var(--height-dropdown-option);

		.mdc-list-item__primary-text {
			padding-left: 0;
		}
	}

	.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
		padding-left: 16px;
		padding-right: 16px;

		&.indented {
			padding-left: 32px;
		}
	}
}
