:root {
	--width-navigation-sidebar: 240px;
	--height-navigation-sidebar: 224px;
	--font-primary: 'NeueFrutigerWorld-Book';
	--font-secondary: 'NeueFrutigerWorld';
	--font-ternary: 'NeueFrutigerWorld-X';
	--box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 20%);
	--box-shadow-small: 0 0 6px 0 rgba(0, 0, 0, 20%);
	--margin-button-icon: 8px;
	--margin-browse-bottom: 50px;
	--margin-section: 16px;
	--spacing: 24px;
	--padding-form-bottom: 150px;
	--image-size-content-list: 72px;
	--image-size-content-list-small: 62px;
	--height-dropdown-option: 42px;
	--gap-form-column: 48px;
	--mat-sys-title-medium-size: 14px;
	--mat-chip-trailing-action-hover-state-layer-opacity: 0;
	--mat-chip-trailing-action-focus-state-layer-opacity: 0;
	--mat-sidenav-container-shape: 0;
}
