.mat-mdc-form-field {
	--mat-form-field-container-text-size: 14px;
	--mat-form-field-container-text-tracking: normal;
	--mat-form-field-subscript-text-line-height: 25px;
	--mat-form-field-subscript-text-tracking: normal;
	--mdc-filled-text-field-label-text-size: 14px;
	--mdc-filled-text-field-label-text-tracking: normal;
	--mdc-filled-text-field-container-color: transparent;
	--mdc-filled-text-field-hover-active-indicator-color: #{var(--color-grey-500)};

	.mdc-text-field {
		padding: 0;

		&:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
			padding-top: 14px;
			padding-bottom: 2px;
		}

		.mat-mdc-form-field-focus-overlay {
			background: transparent;
		}

		.mat-mdc-form-field-infix {
			min-height: 38px;
		}

		.mdc-floating-label {
			top: 25px;
			will-change: initial;

			&--float-above {
				top: 23px;
			}
		}
	}

	.mat-mdc-form-field-subscript-wrapper {
		height: 18px;
	}

	.mat-mdc-form-field-error-wrapper {
		padding: 0;

		.mat-mdc-form-field-error::before {
			display: inline;
		}
	}
}
