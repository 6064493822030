/* stylelint-disable indentation */
@font-face {
	font-family: NeueFrutigerWorld;
	src: url('/assets/fonts/NeueFrutigerWorld-Light/NeueFrutigerWorld-Light.woff2');
	src:
		url('/assets/fonts/NeueFrutigerWorld-Light/NeueFrutigerWorld-Light.woff2') format('woff2'),
		url('/assets/fonts/NeueFrutigerWorld-Light/NeueFrutigerWorld-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: NeueFrutigerWorld;
	src: url('/assets/fonts/NeueFrutigerWorld-Medium/NeueFrutigerWorld-Medium.woff2');
	src:
		url('/assets/fonts/NeueFrutigerWorld-Medium/NeueFrutigerWorld-Medium.woff2') format('woff2'),
		url('/assets/fonts/NeueFrutigerWorld-Medium/NeueFrutigerWorld-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: NeueFrutigerWorld;
	src: url('/assets/fonts/NeueFrutigerWorld-Bold/NeueFrutigerWorld-Bold.woff2');
	src:
		url('/assets/fonts/NeueFrutigerWorld-Bold/NeueFrutigerWorld-Bold.woff2') format('woff2'),
		url('/assets/fonts/NeueFrutigerWorld-Bold/NeueFrutigerWorld-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: NeueFrutigerWorld-X;
	src: url('/assets/fonts/NeueFrutigerWorld-Heavy/NeueFrutigerWorld-Heavy.woff2');
	src:
		url('/assets/fonts/NeueFrutigerWorld-Heavy/NeueFrutigerWorld-Heavy.woff2') format('woff2'),
		url('/assets/fonts/NeueFrutigerWorld-Heavy/NeueFrutigerWorld-Heavy.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: NeueFrutigerWorld-Book;
	src: url('/assets/fonts/NeueFrutigerWorld-Book/NeueFrutigerWorld-Book.woff2');
	src:
		url('/assets/fonts/NeueFrutigerWorld-Book/NeueFrutigerWorld-Book.woff2') format('woff2'),
		url('/assets/fonts/NeueFrutigerWorld-Book/NeueFrutigerWorld-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
