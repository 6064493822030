/* stylelint-disable */

$snack-bar-error-background-color: var(--color-error-100);
$snack-bar-error-color: var(--color-error-500);

.mdc-snackbar.mat-mdc-snack-bar-container {
	margin: 24px;
	max-width: 33vw;

	.mdc-snackbar__surface {
		background-color: var(--color-label) !important;
		min-width: auto;
		box-sizing: border-box !important;
		height: auto !important;

		.mat-mdc-snack-bar-label {
			color: var(--color-white);
		}

		.mdc-button.mat-mdc-snack-bar-action {
			background-color: transparent;
			border: none;
			color: var(--color-white);
			min-width: auto;

			&:active {
				color: var(--color-gray-100);
			}
		}

		.mdc-button__ripple {
			display: none;
		}
	}

	&.snack-error .mdc-snackbar__surface {
		border: 1px solid $snack-bar-error-color;
		background-color: $snack-bar-error-background-color !important;

		.mat-mdc-snack-bar-label {
			color: $snack-bar-error-color;
		}
	}
}
