/* stylelint-disable selector-no-vendor-prefix */

@mixin placeholder-color($color) {
	&::-webkit-input-placeholder {

		/* Chrome/Opera/Safari */
		color: $color;
	}

	&::-moz-placeholder {

		/* Firefox 19+ */
		color: $color;
	}

	&:-ms-input-placeholder {

		/* IE 10+ */
		color: $color;
	}

	&:-moz-placeholder {

		/* Firefox 18- */
		color: $color;
	}
}
@mixin clearfix() {
	&::before,
	&::after {
		content: ' ';
		display: table;
	}

	&::after {
		clear: both;
	}
}
@mixin sort-inline-svg-color($svg-color) {
	// stylelint-disable
	::ng-deep [sort-field].sort-asc,
	::ng-deep [sort-field].sort-desc {
		.img {
			.svg-color {
				fill: $svg-color !important;
			}
		}
	}
	// stylelint-enable
}
@mixin bar-view-container() {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
