.nutriu {
	.mat-icon {
		&.small {
			width: 16px;
			height: 16px;
			min-width: 16px;
			min-height: 16px;
		}

		&.large {
			width: 32px;
			height: 32px;
			min-width: 32px;
			min-height: 32px;
		}

		&.extra-large {
			width: 48px;
			height: 48px;
			min-width: 48px;
			min-height: 48px;
		}

		&.primary {
			color: var(--color-primary-500);
		}

		&.success {
			color: var(--color-success-500);
		}

		&.warning {
			color: var(--color-warn-500);
		}

		&.error {
			color: var(--color-error-500);
		}

		&.with-background {
			background-color: var(--color-primary-100);
			color: var(--color-primary-500);

			&.small {
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 10px;
					height: 10px;
				}
			}

			&.large {
				padding: 8px;
				border-radius: 16px;
			}

			&.extra-large {
				padding: 12px;
				border-radius: 24px;
			}

			&.success {
				background-color: var(--color-success-100);
				color: var(--color-success-500);
			}

			&.warning {
				background-color: var(--color-warn-100);
				color: var(--color-warn-500);
			}

			&.error {
				background-color: var(--color-error-100);
				color: var(--color-error-500);
			}
		}
	}
}
