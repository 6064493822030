@use 'utils/mixins';
@use 'utils/z-indexes';

.section-box-container {
	display: flex;
	margin: 0 8px;

	&--half {
		flex: 1;
		display: flex;
	}
}

.section-box {
	background-color: var(--color-white);
	margin: var(--margin-section);

	&--full {
		width: 100%;
	}

	&--omit-vertical-margins {
		margin-top: 0;
		margin-bottom: 0;
	}

	&--omit-bottom-margin {
		margin-bottom: 0;
	}

	&--omit-top-margin {
		margin-top: 0;
	}

	&--squeezed {
		margin: 0 8px;
	}

	&--no-margin {
		margin: 0;
	}

	&--relative {
		position: relative;
	}
}

.section-box-header {
	@include mixins.clearfix();
	padding: var(--spacing);

	&--relative {
		position: relative;
	}

	&--padding {
		padding-left: 8px;
		padding-right: 8px;
	}

	& &__img {
		display: inline-block;
		vertical-align: middle;
		margin-right: var(--spacing);
	}

	& &__title {
		font-size: 20px;
		font-weight: normal;
		display: inline-block;
		vertical-align: middle;
		color: var(--color-label);
		margin: 0;

		&--warning {
			color: var(--color-error-500);
		}
	}

	& &__selectable {
		display: flex;
		align-items: center;
		float: right;
		margin-top: 8px;
		flex-basis: 210px;
	}

	& &__toggler {
		position: absolute;
		top: var(--spacing) + 5;
		right: var(--spacing);
		height: 32px;
		font-size: 12px;
		line-height: 1.5;
	}
}

.section-box-form {
	position: relative;
	display: block;
	width: 100%;
	padding: 0 var(--spacing) var(--spacing);
	background-color: var(--color-white);
	box-shadow:
		0 4px 10px 0 rgba(0, 0, 0, 0.08),
		0 -4px 10px 0 rgba(0, 0, 0, 0.08); /* stylelint-disable-line */
	border-style: solid;
	border-width: 0.5px;
	border-image-source: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.8),
		rgba(255, 255, 255, 0.4) 5%,
		rgba(255, 255, 255, 0) 20%,
		rgba(255, 255, 255, 0)
	); /* stylelint-disable-line */
	border-image-slice: 1; /* stylelint-disable-line */
	z-index: z-indexes.$section-box-form;

	&--padded {
		padding: 0 var(--spacing) var(--spacing);
	}

	&--no-padding {
		padding: 0;
	}

	&--no-shadow {
		box-shadow: none;
	}
}

.section-box-content {
	padding: 0 var(--spacing) var(--spacing);

	&--padded {
		padding: 0 var(--spacing) 0;
	}

	&--padded-bottom {
		padding-bottom: 8px;
	}

	&--centered-button {
		text-align: center;
		padding-top: 16px;
	}

	&--left-button {
		padding: {
			left: 8px;
			top: 22px;
			bottom: 26px;
		}
	}

	&--full {
		padding: {
			right: 0;
			left: 0;
		}
	}

	&--no-bottom-padding {
		padding-bottom: 0;
	}
}
