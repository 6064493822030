.nut-checkbox.mat-mdc-checkbox {
	--mdc-checkbox-state-layer-size: 16px;
	line-height: 16px;
	pointer-events: none;

	.mdc-checkbox {
		padding: 0;
		width: var(--mdc-checkbox-state-layer-size, 16px);
		height: var(--mdc-checkbox-state-layer-size, 16px);
		border: none;

		.mdc-checkbox__checkmark {
			color: var(--color-white);
		}

		.mdc-checkbox__mixedmark {
			border-color: var(--color-white);
		}

		.mdc-checkbox__background {
			top: 0;
			left: 0;
			width: var(--mdc-checkbox-state-layer-size, 16px);
			height: var(--mdc-checkbox-state-layer-size, 16px);
			border-color: var(--color-label) !important;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&.mat-mdc-checkbox-checked,
	.mdc-checkbox__native-control:indeterminate ~ {
		.mdc-checkbox__background {
			background-color: var(--color-label) !important;
		}

		.mdc-checkbox__mixedmark {
			background-color: var(--color-label);
		}
	}

	&.mat-mdc-checkbox-disabled {
		.mdc-checkbox__background {
			border-color: var(--color-disabled) !important;
		}

		&.mat-mdc-checkbox-checked .mdc-checkbox__background,
		.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
			background-color: var(--color-disabled) !important;
		}
	}

	&.nut-checkbox--size-medium {
		--mdc-checkbox-state-layer-size: 18px;
		line-height: 18px;

		.mdc-checkbox {
			width: var(--mdc-checkbox-state-layer-size, 18px);
			height: var(--mdc-checkbox-state-layer-size, 18px);
		}
	}

	&.nut-checkbox--size-big {
		--mdc-checkbox-state-layer-size: 20px;
		line-height: 20px;

		.mdc-checkbox {
			width: var(--mdc-checkbox-state-layer-size, 20px);
			height: var(--mdc-checkbox-state-layer-size, 20px);
		}
	}
}
